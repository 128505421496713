import { type BadgeProps, Badge as _Badge, styled } from "@mui/material"

const StyledBadge = styled(_Badge)(({ theme, color }) => ({
	color: color || theme.palette.blue[400],
	variants: [
		{
			props: { color: "warning" },
			style: {
				"& .MuiBadge-badge": {
					backgroundColor: theme.palette.yellow[300],
				},
			},
		},
	],
}))

const StyledTextBadge = styled(StyledBadge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: `-${theme.spacing(1)}`,
		top: 8,
		padding: "0 4px",
	},
}))

export function TextBadge(props: BadgeProps) {
	return <StyledTextBadge {...props} />
}

export function Badge(props: BadgeProps) {
	return <StyledBadge {...props} />
}
