import type { ReactNode } from "react"
import type { MinimumIdentifiableEntity } from "../types/entities"
import { type LinkableGlobalEntityFnOptions, getGlobalEntityLinkAttrs } from "../utils/global-routes"
import type { GlobalEntityPossibleTitleKeys } from "../utils/global-titles"

export function GlobalEntityTitle<E extends MinimumIdentifiableEntity>({
	entity,
	subRoute,
	defaultLabel = "-",
	labelType = "label",
	children,
	className,
	showBrandedData = false,
	showLocaleData = false,
}: {
	entity: E
	subRoute?: string
	defaultLabel?: string
	labelType?: GlobalEntityPossibleTitleKeys
	className?: string
	children?: ReactNode
} & LinkableGlobalEntityFnOptions) {
	const titleAttrs = getGlobalEntityLinkAttrs<E>(entity, { subRoute, showBrandedData, showLocaleData })
	const fLabel = titleAttrs[labelType] ?? titleAttrs.label ?? defaultLabel

	return (
		<span className={className}>
			{fLabel}
			{children}
		</span>
	)
}
