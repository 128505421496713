import { createTheme } from "@mui/material"
import { grey, purple, red, yellow } from "@mui/material/colors"
import { colorsDeclinations, commonTheme } from "./common"

export const darkTheme = createTheme({
	...commonTheme(),
	palette: {
		mode: "dark",
		tonalOffset: 0.2,
		primary: {
			main: red[500],
			contrastText: "#fff",
		},
		secondary: {
			main: purple[500],
		},
		error: {
			main: red[500],
		},
		warning: {
			main: yellow[500],
		},
		grey,
		...colorsDeclinations(["red", "blue", "yellow"]),
	},
	shape: {
		borderRadius: 4,
	},
})
