import { useMemo } from "react"
import { useMediaQuery } from "usehooks-ts"

export const ViewportSizesEnum = {
	SM: "SM",
	MD: "MD",
	LG: "LG",
	XL: "XL",
	"2XL": "2XL",
} as const

type ViewportSizesKeys = keyof typeof ViewportSizesEnum

const ViewportSizes: Record<ViewportSizesKeys, { min: number; max?: number }> = {
	[ViewportSizesEnum.SM]: { min: 640 },
	[ViewportSizesEnum.MD]: { min: 768 },
	[ViewportSizesEnum.LG]: { min: 1024 },
	[ViewportSizesEnum.XL]: { min: 1280 },
	[ViewportSizesEnum["2XL"]]: { min: 1536 },
}

export const useMinSM = () => useMediaQuery(`(min-width: ${ViewportSizes.SM.min}px)`)

export const useMinMD = () => useMediaQuery(`(min-width: ${ViewportSizes.MD.min}px)`)

export const useMinLG = () => useMediaQuery(`(min-width: ${ViewportSizes.LG.min}px)`)

export const useMinXL = () => useMediaQuery(`(min-width: ${ViewportSizes.XL.min}px)`)

export const useMin2XL = () => useMediaQuery(`(min-width: ${ViewportSizes["2XL"].min}px)`)

export const useAvailableViewportSizes = () => {
	const xxl = useMin2XL()
	const xl = useMinXL()
	const lg = useMinLG()
	const md = useMinMD()
	const sm = useMinSM()

	const detectedViewportSizes = useMemo(
		() =>
			Object.entries({
				[ViewportSizesEnum.SM]: sm,
				[ViewportSizesEnum.MD]: md,
				[ViewportSizesEnum.LG]: lg,
				[ViewportSizesEnum.XL]: xl,
				[ViewportSizesEnum["2XL"]]: xxl,
			})
				.filter(([_, matches]) => Boolean(matches))
				.map(([size]) => size),
		[xxl, xl, lg, md, sm],
	)
	return (detectedViewportSizes.length > 0 ? detectedViewportSizes : [ViewportSizesEnum.SM]) as ViewportSizesKeys[]
}
