export const VIDEO_FILE_SIZE_LIMIT = 10 * 1024 * 1024 * 1024 // 10Go

// DM supports officially MP4, WMV, MPEG, AVI, DV, 3GP, FLV, .MOV
export const VIDEO_FILE_MIME_TYPES = {
	"video/mp4": [".mp4"],
	"video/quicktime": [".mov"],
	"video/x-msvideo": [".avi"],
	"video/x-ms-wmv": [".wmv"],
	"video/x-flv": [".flv"],
	"video/mpeg": [".mpeg", ".mpg"],
	"video/3gpp": [".3gp"],
}

export const VIDEO_MIME_TYPES_EXT_EXPR = Object.values(VIDEO_FILE_MIME_TYPES).flat()

export const ACCEPT_VIDEO_MIME_TYPES_EXPR = Object.keys(VIDEO_FILE_MIME_TYPES).join(", ")
