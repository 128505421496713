import clsx from "clsx"
import type { HTMLAttributes } from "react"

export type BadgeColor = "neutral" | "success" | "info" | "warning" | "error"

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
	variant?: "default" | "outline"
	color?: BadgeColor
}

export function Badge({ color = "neutral", variant = "default", className, children, ...rest }: BadgeProps) {
	if (!children) return null
	const hasOutline = variant === "outline"
	return (
		<span
			className={clsx(
				"rounded-full text-xs font-medium px-2 whitespace-nowrap animate-quick-appear text-center",
				{
					"text-gray-700 bg-gray-100": color === "neutral",
					"text-emerald-700 bg-emerald-100": color === "success",
					"text-blue-700 bg-blue-100": color === "info",
					"text-amber-700 bg-amber-100": color === "warning",
					"text-red-700 bg-red-100": color === "error",
				},
				hasOutline
					? {
							"border-solid border-[1px]": hasOutline,
							"border-gray-700": color === "neutral",
							"border-emerald-700": color === "success",
							"border-blue-700": color === "info",
							"border-amber-700": color === "warning",
							"border-red-700": color === "error",
						}
					: {},
				className,
			)}
			{...rest}
		>
			{children}
		</span>
	)
}
