import { getEntityBrandLocaleTitles } from "../pages/shared/hooks/use-entity-brands-data"
import type { Entities, MinimumIdentifiableEntity, MinimumReferentiableEntity } from "../types/entities"
import {
	isAuthorEntity,
	isBrandLocaleDataEntity,
	isCompanyEntity,
	isCompanyStaffEntity,
	isFestivalAwardEntity,
	isFestivalCompetitionEntity,
	isFestivalEditionEntity,
	isFestivalEntity,
	isMinimumReferentiableEntity,
	isMovieEntity,
	isNewsEntity,
	isPersonEntity,
	isSeriesEntity,
	isSeriesEpisodeEntity,
	isSeriesSeasonEntity,
	isTheaterEntity,
	isUserEntity,
	isVideoB2BEntity,
	isVideoLegacyEntity,
	isWidgetEntity,
} from "../types/entities"
import { formatEpisodeNumber, formatName, formatSeasonNumber } from "./formatters"

export type TitleGlobalEntityFnOptions = {
	showBrandedData?: boolean
	showLocaleData?: boolean
}
export type TitleGlobalEntityFn = <T extends MinimumReferentiableEntity>(
	entity: T,
	opts: TitleGlobalEntityFnOptions,
) => {
	entityLabel: string // main entity label, ex: Cannes 2023
	relatedEntityLabel?: string // related entity label, ex: Cannes 2023
	refLabel: string // title generally used in tables when dealing only with ref
	minshortLabel: string // title generally used in tables
	shortLabel: string // title generally used in page titles
	label: string // general label, generally embeding with the associated ref, used in breadcrumbs
	fullLabel: string // full entity type label + label + ref
}

export type GlobalEntityPossibleTitleKeys = keyof ReturnType<TitleGlobalEntityFn>

export const TITLES_ENTITY_MAPPING: Partial<{
	[_K in Entities]: TitleGlobalEntityFn
}> = {
	Author: (entity) => {
		if (!isAuthorEntity(entity)) throw new Error("Author global link requirements failed")

		const { ref, name, __typename } = entity
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	Company: (entity) => {
		if (!isCompanyEntity(entity)) throw new Error("Company global title requirements failed")

		const { ref, name, __typename, country, companyCountry } = entity
		const actualCountry = companyCountry ?? country
		const countryPart = actualCountry ? ` (${actualCountry.name})` : ""

		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} #${ref} ${countryPart}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	CompanyStaff: (entity) => {
		if (!isCompanyStaffEntity(entity)) throw new Error("CompanyStaff global title requirements failed")

		const { ref, firstName, lastName, __typename } = entity
		const name = [firstName, lastName].filter(Boolean).join(" ")
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	EditorialReview: (entity) => {
		const { ref } = entity
		const name = entity.name ?? entity.title ?? "-"
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `Editorial Review ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `Editorial Review ${name} ${ref}`,
		}
	},
	Festival: (entity) => {
		if (!isFestivalEntity(entity)) throw new Error("Festival global title requirements failed")

		const { ref, name, festivalName, __typename } = entity
		const fName = name ?? festivalName ?? "-"
		return {
			entityLabel: fName,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${fName}`,
			fullLabel: `${__typename} ${fName} ${ref}`,
		}
	},
	FestivalEdition: (entity) => {
		if (!isFestivalEditionEntity(entity)) throw new Error("FestivalEdition global title requirements failed")

		const { ref, year, editionNumber, name, editionName, festival, __typename } = entity
		const title = `${name ?? editionName ?? editionNumber ?? ""}`
		const mainPart = `${`${festival.name ?? festival.festivalName ?? "-"}`} ${title} - ${year.toString()}`
		return {
			entityLabel: mainPart,
			refLabel: `${ref}`,
			minshortLabel: year.toString(),
			shortLabel: `${__typename} ${ref}`,
			label: `${mainPart}`,
			fullLabel: `Festival Edition ${mainPart} ${ref}`,
		}
	},
	FestivalCompetition: (entity) => {
		if (!isFestivalCompetitionEntity(entity)) throw new Error("FestivalCompetition global title requirements failed")

		const { ref, name, __typename } = entity
		const targetName = name ?? "-"
		return {
			entityLabel: targetName,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${targetName}`,
			fullLabel: `${__typename} ${targetName} ${ref}`,
		}
	},
	FestivalAward: (entity) => {
		if (!isFestivalAwardEntity(entity)) throw new Error("FestivalAward global title requirements failed")
		const { ref, name, __typename } = entity
		return {
			entityLabel: name ?? "-",
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	Movie: (entity, { showBrandedData, showLocaleData }) => {
		if (!isMovieEntity(entity)) throw new Error("Movie global title requirements failed")
		const { ref, movieTitle, __typename } = entity
		const eTitle = entity.title ?? movieTitle
		let title = eTitle

		if (isBrandLocaleDataEntity(entity))
			title = getEntityBrandLocaleTitles({ entity, showBrandedData, showLocaleData }).join(", ")

		const targetTitle = title || eTitle
		return {
			entityLabel: targetTitle,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${targetTitle} (${ref})`,
			fullLabel: `${__typename} ${targetTitle} ${ref}`,
		}
	},
	News: (entity) => {
		if (!isNewsEntity(entity)) throw new Error("News global title requirements failed")
		const { ref, title, __typename } = entity
		return {
			entityLabel: title,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${title} ${ref}`,
			fullLabel: `${__typename} ${title} ${ref}`,
		}
	},
	Person: (entity) => {
		if (!isPersonEntity(entity)) throw new Error("PersonEntity global title requirements failed")

		const mainPart = formatName(entity)
		const { ref, __typename } = entity
		return {
			entityLabel: mainPart,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${mainPart} ${ref}`,
			fullLabel: `${__typename} ${mainPart} ${ref}`,
		}
	},
	PressReview: (entity) => {
		const { ref } = entity
		const name = entity.name ?? entity.title ?? "-"
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `Press Review ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `Press Review ${name} ${ref}`,
		}
	},
	Series: (entity, { showBrandedData, showLocaleData }) => {
		if (!isSeriesEntity(entity)) throw new Error("Series global title requirements failed")
		const { ref, seriesTitle, __typename } = entity

		const eTitle = entity.title ?? seriesTitle
		let title = eTitle

		if (isBrandLocaleDataEntity(entity))
			title = getEntityBrandLocaleTitles({ entity, showBrandedData, showLocaleData }).join(", ")

		const targetTitle = title || eTitle
		return {
			entityLabel: targetTitle,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${targetTitle} (${ref})`,
			fullLabel: `${__typename} ${targetTitle} ${ref}`,
		}
	},
	SeriesSeason: (entity) => {
		if (!isSeriesSeasonEntity(entity)) throw new Error("SeriesSeason global title requirements failed")
		const { ref, series, number, __typename } = entity
		const label = `${series.title} ${formatSeasonNumber(number)}`
		const sLabel = formatSeasonNumber(number)

		return {
			entityLabel: label,
			refLabel: `${ref}`,
			minshortLabel: sLabel,
			shortLabel: `${__typename} ${ref}`,
			label: `${label} ${ref}`,
			fullLabel: `${__typename} ${label} ${ref}`,
		}
	},
	SeriesEpisode: (entity, { showBrandedData }) => {
		if (!isSeriesEpisodeEntity(entity)) throw new Error("SeriesEpisode global title requirements failed")

		const eTitle = entity.title ?? entity.episodeTitle
		let title = eTitle
		if (isBrandLocaleDataEntity(entity)) title = getEntityBrandLocaleTitles({ entity, showBrandedData }).join(", ")

		const targetTitle = title || eTitle

		const { ref, series, season, number, episodeNumber, __typename } = entity
		const fNumber = number ?? episodeNumber ?? 0
		const seasonEpisodeNumberLabel = formatEpisodeNumber(season?.number ?? 0, fNumber)
		const eMainTitle = `${targetTitle ? ` - ${targetTitle} ` : ""}`
		const label = `${series.title} ${seasonEpisodeNumberLabel}${eMainTitle}`
		const eLabel = `${seasonEpisodeNumberLabel}${eMainTitle}`

		return {
			entityLabel: `${seasonEpisodeNumberLabel}${eMainTitle}`,
			relatedEntityLabel: label,
			refLabel: `${ref}`,
			minshortLabel: eLabel,
			shortLabel: `${__typename} ${ref}`,
			label: `${label} ${ref}`,
			fullLabel: `${__typename} ${label} ${ref}`,
		}
	},
	VideoLegacy: (entity) => {
		if (!isVideoLegacyEntity(entity)) throw new Error("VideoLegacy global title requirements failed")
		const { ref, title, optionalTitle, generatedTitle } = entity
		const label = (title || generatedTitle) ?? optionalTitle
		return {
			entityLabel: label ?? "-",
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `Video ${ref}`,
			label: `${label} (${ref})`,
			fullLabel: `Video ${label} ${ref}`,
		}
	},
	VideoB2B: (entity) => {
		if (!isVideoB2BEntity(entity)) throw new Error("VideoB2B global title requirements failed")
		const { ref, title, optionalTitle, generatedTitle } = entity
		const label = (title || generatedTitle) ?? optionalTitle
		return {
			entityLabel: label ?? "-",
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `Video ${ref}`,
			label: `${label} (${ref})`,
			fullLabel: `Video ${label} ${ref}`,
		}
	},
	Theater: (entity) => {
		if (!isTheaterEntity(entity)) throw new Error("Theater global title requirements failed")

		const { ref, name, __typename, country } = entity
		return {
			entityLabel: name ?? "-",
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}${country?.name ? ` (${country.name})` : ""}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	Trivia: (entity) => {
		const { ref, __typename } = entity
		const name = entity.name ?? entity.title ?? "-"
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	User: (entity) => {
		if (!isUserEntity(entity)) throw new Error("User global title requirements failed")

		const { ref, __typename, name } = entity
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	Widget: (entity) => {
		if (!isWidgetEntity(entity)) throw new Error("Widget global title requirements failed")

		const { ref, __typename, name } = entity
		return {
			entityLabel: name,
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
	Certificate: (entity) => {
		const { ref, __typename, name } = entity
		return {
			entityLabel: name ?? "",
			refLabel: `${ref}`,
			minshortLabel: ref,
			shortLabel: `${__typename} ${ref}`,
			label: `${name} ${ref}`,
			fullLabel: `${__typename} ${name} ${ref}`,
		}
	},
}

export const getGlobalEntityTitleAttrs = (
	entity: MinimumIdentifiableEntity,
	{ showBrandedData = false, showLocaleData = false }: TitleGlobalEntityFnOptions = {},
) => {
	if (!isMinimumReferentiableEntity(entity))
		throw new Error(`GlobalEntityTitle:minimum requirements not met for entity: ${JSON.stringify(entity)}`)
	if (!(entity.__typename in TITLES_ENTITY_MAPPING))
		throw new Error(`GlobalEntityTitle:unsupported type ${entity.__typename}`)

	return (TITLES_ENTITY_MAPPING[entity.__typename as Entities] as TitleGlobalEntityFn)(entity, {
		showBrandedData,
		showLocaleData,
	})
}
