import clsx from "clsx"
import type { ReactNode } from "react"

import { FormChangeState, useFormChangeState } from "@hooks/use-form-change-state"
import { usePreventNavigation } from "@hooks/use-prevent-navigation"

import { useLocation, useNavigate } from "react-router-dom"
import { HashtagIcon } from "../icons/hashtag-icon"
import { WarningIcon } from "../icons/warning-icon"
import { CopyButton } from "./copy-button"
import { ErrorBoundary } from "./error-boundary"
import { Loader } from "./loader"
import { ProgressBar } from "./progress-bar"

export type CardProps = {
	loading?: boolean
	progressBarPending?: boolean
	progressBarLoading?: boolean
	withProgressBar?: boolean
	isPreventNavigation?: boolean
	wrapperClassName?: string
	className?: string
	children: ReactNode
	header?: ReactNode
	marginBottom?: boolean
	/**
	 * @deprecated not read anymore
	 */
	withPadding?: boolean
	noYPadding?: boolean
	withFormChangeState?: boolean
	color?: "default" | "error" | "warning" | "success"
}

export function Card(props: CardProps) {
	const {
		loading = false,
		progressBarPending = false,
		progressBarLoading = false,
		withProgressBar = false,
		wrapperClassName,
		className,
		children,
		marginBottom,
		header,
		noYPadding = false,
		withFormChangeState = false,
		isPreventNavigation = false,
		color = "default",
	} = props

	usePreventNavigation(isPreventNavigation)

	const formState = useFormChangeState()
	const formStateCss = withFormChangeState
		? {
				"border-yellow-600": formState === FormChangeState.DIRTY,
				"animate-borderGreenToGray": formState === FormChangeState.SAVED,
			}
		: "border-gray-200"

	if (loading) return <Loader />

	return (
		<div
			className={clsx(
				"relative rounded border transition-[border-color] ease-[cubic-bezier(0,0,0.2,1)] duration-1000",
				wrapperClassName,
				{
					"mb-6": marginBottom,
					"border-red-600": color === "error",
					"border-yellow-600": color === "warning" || formState === FormChangeState.DIRTY,
					"border-green-500": color === "success",
				},
				formStateCss,
			)}
		>
			{header && (
				<div
					className={clsx("bg-gray-100 p-2 rounded-t border-b border-gray-200", {
						"bg-yellow-500": color === "warning" || formState === FormChangeState.DIRTY,
						"animate-bgGreenToGray": formState === FormChangeState.SAVED,
						"bg-red-500": color === "error" || formState === FormChangeState.ERROR,
						"text-white":
							color === "warning" ||
							color === "error" ||
							formState === FormChangeState.DIRTY ||
							formState === FormChangeState.ERROR,
					})}
				>
					{withFormChangeState && formState === FormChangeState.DIRTY && (
						<div className="float-right flex gap-1" style={{ marginTop: "0.125rem" }}>
							<WarningIcon size="S" className="text-white" />
							<div className="text-xs text-white">Unsaved changes</div>
						</div>
					)}
					{typeof header === "string" ? <div className="font-bold uppercase text-sm">{header}</div> : header}
				</div>
			)}
			<div
				className={clsx(className, "flex flex-col gap-2", {
					"py-2": !(noYPadding || withProgressBar),
					"py-0": withProgressBar,
				})}
			>
				{withProgressBar && (
					<ProgressBar
						isIndeterminate={progressBarLoading}
						color={progressBarLoading ? "info" : "secondary"}
						visible={progressBarLoading || progressBarPending}
					/>
				)}
				{children}
			</div>
		</div>
	)
}

export function BoundaryCard(props: { children: ReactNode }) {
	return (
		<ErrorBoundary
			fallbackRender={({ error }) => (
				<div className="rounded border border-red-300">
					<div className="p-2 rounded-t bg-red-100 border-b border-red-300 font-bold uppercase text-sm">Error</div>
					<pre className="p-2 rounded-b text-xs overflow-y-auto">{error.stack}</pre>
				</div>
			)}
		>
			{props.children}
		</ErrorBoundary>
	)
}

export type CardHeaderProps = {
	title: string | ReactNode
	actions?: ReactNode
	anchor?: string
}

export function CardHeader({ title, actions, anchor }: CardHeaderProps) {
	const nav = useNavigate()
	const loc = useLocation()
	const anchoredUrl = window.location.href.split("#")?.[0] + anchor
	const titleComp = anchor ? (
		<div className="flex items-center gap-1">
			{title}
			<CopyButton
				value={anchoredUrl}
				icon={<HashtagIcon />}
				title="Copy link to section & navigate"
				className="hover:text-blue-400"
				color="transparent"
				copiedColor="transparent"
				onClick={() => {
					if (loc.hash !== anchor) nav(`./${anchor}`, { replace: false })
				}}
			/>
		</div>
	) : (
		title
	)
	return (
		<div className="flex items-center justify-between">
			<div className="font-bold uppercase text-sm" {...(anchor ? { id: anchor.replace("#", "") } : {})}>
				{titleComp}
			</div>
			{actions ? <div className="flex gap-2">{actions}</div> : null}
		</div>
	)
}

export const itemPadding = "px-2"
