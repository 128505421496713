import { nonempty, refine, string } from "superstruct"

export const isValidURL = (value: string | URL | null | undefined) => {
	if (!value) return false

	let parsedUrl: URL
	try {
		parsedUrl = new URL(value)
	} catch (_e) {
		return false
	}
	// some strings like "toto:lol" are properly parsed but we want to reject them
	if (!parsedUrl.hostname) return false
	return true
}

export const Url = refine(nonempty(string()), "Url", (value) => {
	return isValidURL(value)
})
