import {
	IMAGES_MIME_TYPES_HEADERS,
	IMG_DEFAULT_DIMENSIONS_RANGE,
	IMG_FILE_SIZE_LIMIT,
	type ImageDimensionsRange,
	ImageLoaderResult,
	SUPPORTED_IMAGE_FORMATS,
	VIDEO_FILE_SIZE_LIMIT,
	VIDEO_MIME_TYPES_EXT_EXPR,
	WEBP_MIME_TYPE,
	imageDimensionsRangePlainValidator,
	imageLoader,
	isValidPrice,
	isValidURL,
	maxFileSize,
	isMaxFloat as validatorIsMaxFloat,
	isMinFloat as validatorIsMinFloat,
} from "@allocine/common-utils"
import { isDefined } from "./types"

export const ValidationSuccess = undefined

type ValidationResult = string | undefined
// biome-ignore lint/suspicious/noExplicitAny: can be anything
type Validator = (...args: any[]) => ValidationResult | Promise<ValidationResult>

const imageDimensionsRangeValidator =
	(
		validate: (
			image: HTMLImageElement,
			limitsRange: ImageDimensionsRange,
			resolve: (s: ValidationResult) => void,
		) => void,
	) =>
	(value: unknown, limitsRange: ImageDimensionsRange) =>
		new Promise<ValidationResult>((resolve, reject) => {
			imageLoader(value)
				.then((img) => validate(img, limitsRange, resolve))
				.catch((e) => {
					if (e instanceof Error) {
						if (e.message === ImageLoaderResult.INTERNAL_ERROR) return resolve(e.message)
						if (e.message === ImageLoaderResult.BAD_PARAMETERS) return resolve(ValidationSuccess)
					}
					return reject(e)
				})
		})

export const isEmail = (value: string): ValidationResult =>
	value && /^[\w._+-]+@[\w._+-]+\.\w{2,}$/.test(value) ? ValidationSuccess : "Invalid email address."

export const isJSON = (value: string): ValidationResult => {
	try {
		JSON.parse(value)
		return ValidationSuccess
	} catch (_e) {
		return "Invalid JSON structure"
	}
}

export const isURL = (value: string): ValidationResult =>
	value && isValidURL(value) ? ValidationSuccess : "Invalid URL."

// biome-ignore lint/suspicious/noExplicitAny: can be anything
export const isPositiveNumber = (value: any): ValidationResult =>
	typeof value === "number" && value >= 0 ? ValidationSuccess : "Must be positive"

export const isNumberLike = (value?: string | number | null): ValidationResult => {
	if (typeof value === "number") return ValidationSuccess

	if (typeof value === "string") {
		const parsed = Number.parseFloat(value)
		if (typeof parsed === "number" && !Number.isNaN(parsed)) return ValidationSuccess
	}

	return "Must be a number"
}

// biome-ignore lint/suspicious/noExplicitAny: can be anything
export const isNotEmpty = (value: any): ValidationResult => {
	if (typeof value === "string") {
		if (value !== "") return ValidationSuccess
	} else if (typeof value === "number") {
		if (Number.isFinite(value)) return ValidationSuccess
	} else if (typeof value === "boolean") {
		return ValidationSuccess
	} else if (Array.isArray(value)) {
		if (value.length > 0) return ValidationSuccess
	} else if (typeof value === "object") {
		if (value !== null) return ValidationSuccess
	}
	return "Required."
}

export const exactLength = (value: string, length: number): ValidationResult =>
	value && value.length === length ? ValidationSuccess : `Must be ${length} characters.`

export const minLength = (value: string, length: number): ValidationResult =>
	value && value.length >= length ? ValidationSuccess : `Must be at least ${length} characters.`

export const betweenLength = (value: string, [minLength = 1, maxLength = 255]: number[]): ValidationResult =>
	typeof value === "string" && value.length >= minLength && value.length <= maxLength
		? ValidationSuccess
		: `Must be at between ${minLength} and ${maxLength} characters.`

export const maxLength = (value: string, length: number): ValidationResult =>
	value && value.length <= length ? ValidationSuccess : `Must be at most ${length} characters.`

export const between = (
	value: number | null | undefined,
	[min = 0, max = Number.POSITIVE_INFINITY]: number[],
): ValidationResult =>
	typeof value === "number" && value >= min && value <= max ? ValidationSuccess : `Must be between ${min} and ${max}.`

/**
 * Returns an error message if the maximum length is exceeded.
 * @param value - The input value to check.
 * @param length - The maximum allowed length.
 * @returns An error message string if the maximum length is exceeded, or undefined otherwise.
 */
export const maxLengthIfNotEmpty =
	<T extends string | number | unknown[]>(length: number) =>
	(value: T) => {
		if (value && typeof value !== "number" && value.length > length) return `Maximum length is ${length} characters`

		return ValidationSuccess
	}

export const isOptional =
	(validate: Validator): Validator =>
	(...args) =>
		isNotEmpty(args[0]) === ValidationSuccess ? validate(...args) : ValidationSuccess

export const isPhone = (value: string): ValidationResult =>
	value && /^\+?[0-9]{5,}/.test(value) ? ValidationSuccess : "Invalid phone number."

export const isPartialDate = (value: string): ValidationResult =>
	value && /^\d{4}(-(0[1-9]|1[012])(-(0[1-9]|1[0-9]|2[0-9]|3[01]))?)?$/.test(value)
		? ValidationSuccess
		: "Invalid date. Please fill in at least the year."

export const validateImgFileSizeDimLimits = async (
	value: unknown,
	{
		fileSizeLimit = IMG_FILE_SIZE_LIMIT,
		dimensions = [IMG_DEFAULT_DIMENSIONS_RANGE],
	}: { fileSizeLimit?: number; dimensions?: ImageDimensionsRange[] } = {},
) => {
	return (
		(await isImageFile(value)) ||
		(await maxFileSize(value, fileSizeLimit ?? IMG_FILE_SIZE_LIMIT)) ||
		((await Promise.all(dimensions.map((d) => imageDimensionsRange(value, d)))).find((r) => r !== ValidationSuccess) ??
			ValidationSuccess)
	)
}

export const getInferedImageMimeType = (f: File) =>
	new Promise<string | undefined>((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => {
			if (!(reader.result && reader.result instanceof ArrayBuffer)) return reject("Unable to read file headers from image")
			// Extract the file header from the file reader result
			const header = new Uint8Array(reader.result)

			// Define a list of known MIME types and their corresponding header bytes

			// Check the file header against the list of known MIME types
			for (const [mimeType, headerBytes] of Object.entries(IMAGES_MIME_TYPES_HEADERS))
				if (headerBytes.every((byte, index) => byte === header[index])) return resolve(mimeType)

			// Special case for WebP-like: "RIFF" + 4 bytes + "WEBP", especially when dealing with users that rename the extension...
			if (
				header[0] === 0x52 &&
				header[1] === 0x49 &&
				header[2] === 0x46 &&
				header[3] === 0x46 &&
				header[8] === 0x57 &&
				header[9] === 0x45 &&
				header[10] === 0x42 &&
				header[11] === 0x50
			)
				return resolve(WEBP_MIME_TYPE)

			resolve(undefined)
		}
		reader.onerror = reject

		// read as much bytes as needed to cover headers cases
		reader.readAsArrayBuffer(f.slice(0, 12))
	})

export const isImageFile = async (value: string | File | { url: string } | unknown | null) => {
	let type: string | undefined
	if (value instanceof File) {
		type = value.type // default to detected
		try {
			const detectedMimeType = await getInferedImageMimeType(value)
			if (detectedMimeType && detectedMimeType !== value.type) type = detectedMimeType
		} catch (e) {
			return e.message
		}
	}

	if (type && !Object.values(SUPPORTED_IMAGE_FORMATS).includes(type))
		return "Incompatible image file, supported are .gif, .jpg or .png"

	return ValidationSuccess
}

export const imageDimensionsRange = imageDimensionsRangeValidator((img, limitsRange, resolve) => {
	try {
		imageDimensionsRangePlainValidator(img, limitsRange)
		return resolve(ValidationSuccess)
	} catch (e) {
		if (e instanceof Error) return resolve(e.message)
		throw e
	}
})

const kiloByteFormatter = new Intl.NumberFormat("en", {
	style: "unit",
	unit: "kilobyte",
})
const megaByteFormatter = new Intl.NumberFormat("en", {
	style: "unit",
	unit: "megabyte",
})
const gigaByteFormatter = new Intl.NumberFormat("en", {
	style: "unit",
	unit: "gigabyte",
})

type ImageDimension = { width: number; height: number }

const dimFn = (dim: ImageDimension) => `W${dim.width}×H${dim.height}px`

type ImageHintsOptions = {
	maxFileSizeInBytes?: number
	maxDimensions?: ImageDimension
	minDimensions?: ImageDimension
	exactDimensions?: ImageDimension
	extensions?: string[]
}
export const imageHints = (opts?: ImageHintsOptions) => {
	const { extensions, maxFileSizeInBytes, exactDimensions } = {
		maxFileSizeInBytes: IMG_FILE_SIZE_LIMIT,
		extensions: Object.keys(SUPPORTED_IMAGE_FORMATS),
		...opts,
	}
	const minDimensions = (opts?.minDimensions ?? exactDimensions) ? undefined : IMG_DEFAULT_DIMENSIONS_RANGE.min
	const maxDimensions = (opts?.maxDimensions ?? exactDimensions) ? undefined : IMG_DEFAULT_DIMENSIONS_RANGE.max

	const hints: string[] = []

	let hint: string
	if (maxFileSizeInBytes > 1 * 1024 * 1024)
		hint = `max size: ${megaByteFormatter.format(maxFileSizeInBytes / 1024 / 1024)}`
	else hint = `max size: ${kiloByteFormatter.format(maxFileSizeInBytes / 1024)}`

	hints.push(hint)
	hints.push(`files: ${extensions.join(", ")}`)

	if (minDimensions) hints.push(`min dim: ${dimFn(minDimensions)}`)
	if (maxDimensions) hints.push(`max dim: ${dimFn(maxDimensions)}`)
	if (exactDimensions) hints.push(`dim: ${dimFn(exactDimensions)}`)

	return hints.join(", ")
}

export const videoFilesHints = (opts?: {
	maxFileSizeInBytes?: number
	extensions?: string[]
}) => {
	const { maxFileSizeInBytes, extensions } = {
		maxFileSizeInBytes: VIDEO_FILE_SIZE_LIMIT,
		extensions: VIDEO_MIME_TYPES_EXT_EXPR,
		...opts,
	}

	const hints: string[] = []

	let hint: string
	if (maxFileSizeInBytes > 1024 * 1024 * 1024)
		hint = `max size: ${gigaByteFormatter.format(maxFileSizeInBytes / 1024 / 1024 / 1024)}`
	else if (maxFileSizeInBytes > 1024 * 1024)
		hint = `max size: ${megaByteFormatter.format(maxFileSizeInBytes / 1024 / 1024)}`
	else hint = `max size: ${kiloByteFormatter.format(maxFileSizeInBytes / 1024)}`

	hints.push(hint)
	hints.push(`files: ${extensions.join(", ")}`)

	return hints.join(", ")
}

export const isMinFloat = (value: number, min: number): ValidationResult =>
	validatorIsMinFloat(value, min) ? ValidationSuccess : `Should be greater than ${min}`

export const isMaxFloat = (value: number, max: number): ValidationResult =>
	validatorIsMaxFloat(value, max) ? ValidationSuccess : `Should be less than ${max}`

export const validatePrice = (value: number, min: number, max: number) => {
	if (isValidPrice(value, min, max)) return ValidationSuccess
	return `Price must be between ${min} and ${max}`
}

export const isLatitude = (lat?: number | null): ValidationResult =>
	isDefined(lat) && lat >= -90 && lat <= 90 ? ValidationSuccess : `${lat} is not a valid latitude`

export const isLongitude = (lng?: number | null): ValidationResult =>
	isDefined(lng) && lng >= -180 && lng <= 180 ? ValidationSuccess : `${lng} is not a valid longitude`

export const isValidCoordinates = ({ lat, long }: { lat?: number | null; long?: number | null }) => {
	const validationError = isLatitude(lat)
	if (validationError) return validationError
	return isLongitude(long)
}

export const isValidLocale = (value: string) => {
	const regexFormat = /^[a-z]{2,3}_[A-Z]{2}$/
	if (!regexFormat.test(value)) {
		return "Invalid format. Should be in the format fr_FR or bho_IN"
	}
	return ValidationSuccess
}
