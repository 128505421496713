import clsx from "clsx"
import type { ReactNode } from "react"

import FormFieldLabel from "@components/form-field-label"
import { Tooltip } from "react-tooltip"
import { HelpIcon } from "../icons/help-icon"
import { Label } from "./label"

export interface FormFieldProps {
	label?: string | ReactNode
	title?: string
	labelIcon?: ReactNode
	children?: ReactNode
	isRequired?: boolean
	className?: string
	hint?: string | ReactNode
	hintTooltip?: string | ReactNode
	actions?: ReactNode
}

export function FormField(props: FormFieldProps) {
	const { label, labelIcon, children, isRequired, className, hint, hintTooltip, title, actions } = props

	return (
		<div className={clsx("flex flex-col gap-1", className)} title={title}>
			<FormFieldLabel labelIcon={labelIcon} className="text-ellipsis">
				{Boolean(label || actions) && (
					<Label isRequired={isRequired} className="flex gap-1">
						{label}
						{hintTooltip && (
							<span className="help-icon">
								<HelpIcon />
								<Tooltip anchorSelect=".help-icon" className="z-10">
									{hintTooltip}
								</Tooltip>
							</span>
						)}
						{Boolean(actions) && <div className="text-xs text-gray-500 ml-2 overflow-hidden">{actions}</div>}
					</Label>
				)}
			</FormFieldLabel>
			{children}
			{hint ? <div className="text-xs text-gray-500">{hint}</div> : null}
		</div>
	)
}
