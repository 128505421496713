import { createContext } from "react"

import type { ReactNode } from "react"
import { useNotifications } from "../../../components/notification"
import { useGetFestivalDefaultContextQuery } from "../../../graphql/apollo"
import type { GetFestivalDefaultContextQuery } from "../../../graphql/types"
import { NotFound } from "../../errors/not-found"

/**
 * All of the festival locale/brand related code below is a temporary way to handle locale informations
 * and should be removed in the future, ideally, it should be handled from user preferences
 */
type FestivalContext = {
	locale: NonNullable<GetFestivalDefaultContextQuery["festivalDefaultLocale"]>
	brand: NonNullable<GetFestivalDefaultContextQuery["festivalDefaultBrand"]>
}

export const FestivalContext = createContext<FestivalContext | undefined>(undefined)

export const FestivalContextProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	const { notify } = useNotifications()
	const { data, loading } = useGetFestivalDefaultContextQuery({
		onError: () => {
			notify("error", "Failed to fetch festival default context")
		},
	})
	const locale = data?.festivalDefaultLocale
	const brand = data?.festivalDefaultBrand

	if (loading) return null
	if (!(locale && brand)) return <NotFound />

	return <FestivalContext.Provider value={{ locale, brand }}>{children}</FestivalContext.Provider>
}
