import { useEffect, useMemo, useState } from "react"
import { isDefined } from "./types"

export enum LocalStorageKeysEnum {
	APP_VERSION = "app:version",
	STORAGE_LATEST_VERSION = "app:storage:latest_version",
	ACCESS_TOKEN_KEY = "auth:access_token",
	ORIGINAL_TOKEN_KEY = "auth:original_token",
}

const MANDATORY_LOCAL_STORAGE_KEYS = [
	LocalStorageKeysEnum.APP_VERSION,
	LocalStorageKeysEnum.STORAGE_LATEST_VERSION,
	LocalStorageKeysEnum.ACCESS_TOKEN_KEY,
	LocalStorageKeysEnum.ORIGINAL_TOKEN_KEY,
] as string[]

export const clearStorage = () => localStorage.clear()

export const getLocalStorageOldestKeys = () => {
	const mappedEntriesKeys = new Map<string, string>()
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i)
		if (!key) continue

		const entry = readVanillaFromStorage(key)
		if (isEntryWithMetadata(entry)) {
			mappedEntriesKeys.set(entry.metadata.createdAt, key)
		} else mappedEntriesKeys.set(new Date("1900-01-01").toISOString(), key) // when no timestamp is set, default to an older one for later deletion
	}
	const sortedEntries = [...mappedEntriesKeys.entries()].sort(([aTs], [bTs]) => aTs.localeCompare(bTs))
	return sortedEntries.filter(([, key]) => !MANDATORY_LOCAL_STORAGE_KEYS.includes(key))
}

export const cleanUpLocalStorageOldestKeysFirst = ({ limit = 10 }: { limit?: number } = {}) => {
	const sortedEntries = getLocalStorageOldestKeys()
	const entriesToDelete = sortedEntries.slice(0, limit)
	for (const [, key] of entriesToDelete) localStorage.removeItem(key)
	return entriesToDelete.length
}

type EntryWithMetadata<T> = {
	value: T
	metadata: {
		createdAt: string
	}
}

// biome-ignore lint/suspicious/noExplicitAny: large typeguard
const isEntryWithMetadata = <T>(entry: any): entry is EntryWithMetadata<T> =>
	entry !== null && typeof entry === "object" && "metadata" in entry && "createdAt" in entry.metadata

const setEntryWithMetadata = <T>({ key, value }: { key: string; value: T }) => {
	if (!isDefined(value)) return localStorage.removeItem(key)

	const augmentedEntry: EntryWithMetadata<T> = {
		value,
		metadata: {
			createdAt: new Date().toISOString(),
		},
	}
	return localStorage.setItem(key, JSON.stringify(augmentedEntry))
}

export const removeFromStorage = (key: string) => localStorage.removeItem(key)

const readVanillaFromStorage = <T>(key: string) => {
	const value = localStorage.getItem(key)
	if (!isDefined(value)) return null

	const parsed = JSON.parse(value) as T | EntryWithMetadata<T>
	return parsed
}

export const readFromStorage = <T>(key: string): T | null => {
	const entry = readVanillaFromStorage<T>(key)
	if (!isDefined(entry)) return null

	if (!isEntryWithMetadata<T>(entry)) {
		writeToStorage(key, entry) // migrate old entries on the fly
		return readFromStorage<T>(key)
	}
	return entry.value
}

export const writeToStorage = <T>(key: string, value: T) => {
	try {
		setEntryWithMetadata({ key, value })
	} catch (e) {
		if (
			(e instanceof Error || e instanceof DOMException) &&
			(e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED" || e.message.match(/quota/i))
		) {
			const cleanedKeys = cleanUpLocalStorageOldestKeysFirst()
			writeToStorage(key, value)
			return console.warn(`app:local storage needed cleanup, cleaned ${cleanedKeys} keys`)
		}
		throw e
	}
}

export const useStorage = <T>(key: string, initialValues: T) => {
	const [value, setValue] = useState<T>(() => readFromStorage(key) || initialValues)
	const handleStorage = useMemo(
		() => (event: StorageEvent) => {
			if (event.key === key) setValue(readFromStorage(key) || initialValues)
		},
		[initialValues, key],
	)
	useEffect(() => {
		window.addEventListener("storage", handleStorage)
		return () => {
			window.removeEventListener("storage", handleStorage)
		}
	}, [handleStorage])

	return value
}
